'use client'
import { Suspense, useEffect } from 'react'
import useDatalayer from '@/hooks/useDatalayer'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import { auth } from '@/config/DBConnection'
import { useRouter, useSearchParams } from 'next/navigation'
import { removeAuthCookies } from '@/context/auth/authServerActions'
import { Button, Loader } from '@umahealth/occipital'
import { useUpdateTokens } from './hooks/useUpdateTokens'
import { saveUtmParams } from '@/context/saveUtmParams'
import Status from '@/components/GeneralComponents/Problem/Status'
import { useSignInWithCustomToken } from './hooks/useSignInWithCustomToken'
import { LogoSelector } from './email/LogoSelector'
import Image from '@/reusableComponents/Image'
import Background from '@/assets/backgroundLogin.png'
import Doctor from '@/assets/doctorLogin.png'
//import { trackEntryPage } from '@/events/signUpEvents'

export default function LoginView() {
	return (
		<Suspense
			fallback={
				<div className='h-full grid place-content-center justify-center h gap-4'>
					<Loader className='m-auto' size='size-7' color='stroke-primary' />
				</div>
			}
		>
			<LoginContent />
		</Suspense>
	)
}

function LoginContent() {
	const router = useRouter()
	const searchParams = useSearchParams()
	const redirect = searchParams?.get('redirect')
	const customToken = searchParams?.get('customToken')
	const verifyCache = searchParams?.get('verifyCache')
	const hasIomaToken = !!searchParams?.get('token') 

	const signInWithCustomTokenMutation = useSignInWithCustomToken({
		onSuccess: () => {
			router.replace(redirect ?? '/')
		}
	})

	const updateTokens = useUpdateTokens({
		options: {
			onSuccess: () => {
				// Construir la URL de redirección con los parámetros existentes
				const redirectUrl = redirect ?? '/'
				const baseUrl = new URL(redirectUrl, window.location.origin)

				// Agregar los parámetros originales si es necesario
				const queryParams = new URLSearchParams(window.location.search)
				queryParams.delete('redirect')
				queryParams.delete('verifyCache')
				for (const [key, value] of queryParams.entries()) {
					baseUrl.searchParams.append(key, value)
				}

				// Realizar la redirección
				router.replace(baseUrl.toString())
			},
			onError: async () => {
				localStorage.clear()
				removeAuthCookies()
				signOut(auth)
				window.location.replace('/login')
			}
		}
	})

	useEffect(() => {
		if (verifyCache === 'true') {
			const authStateChange = onAuthStateChanged(auth, (user) => {
				if (user) {
					updateTokens.mutate(user)
				}
			})
			return () => authStateChange()
		}
	}, [auth, verifyCache])

	useEffect(() => {
		if (searchParams) {
			if (customToken) {
				signInWithCustomTokenMutation.mutate({customToken, hasIomaToken})
			}
			saveUtmParams({ useSearchParams: searchParams })
		}
	}, [])

	useDatalayer({
		'instance': process.env.NEXT_PUBLIC_DATALAYER_INSTANCE,
		'page_title': 'Login',
		'content_group': 'login',
		'event': 'custom_pageview',
		'page_location': `${process.env.NEXT_PUBLIC_PAGE_LOCATION}/login`
	})

	if (signInWithCustomTokenMutation.isError) {
		return (
			<Status
				type='warning'
				title='El link de inicio de sesión ya expiró'
				iconName='serverError'
				showError={false}
				subtitle={`Error detallado: ${signInWithCustomTokenMutation.error?.message}`}
			>
				<Button
					type='button'
					variant='filled'
					onClick={() => signInWithCustomTokenMutation.reset()}
				>
					Ingresar nuevamente
				</Button>
			</Status>
		)
	}

	if ( (customToken || signInWithCustomTokenMutation.isSuccess || signInWithCustomTokenMutation.isLoading) || (updateTokens.isSuccess || updateTokens.isPending) ) {
		return (
			<div className='h-full grid place-content-center justify-center h gap-4'>
				<Loader className='m-auto' size='size-7' color='stroke-primary' />
			</div>
		)
	}

	return (
		<div className="w-full h-screen bg-transparent relative">
			<Image
				className="absolute top-0 bottom-0 left-0 right-0 -z-1 h-full object-cover"
				src={Background}
				alt="fondo"
			/>
			<div className="shadow-xl z-20 rounded-lg w-full h-full flex flex-col justify-between">
				<div className='z-20'>
					<h1 className="text-white text-lg text-center font-semibold mb-2 mt-8">
						Te damos la bienvenida a
					</h1>
					<LogoSelector white/>
				</div>
				<Image
					className="absolute bottom-0 left-0 right-0 -z-4 object-contain h-[81%]"
					src={Doctor}
					alt="doctor"
				/>
				<div className='bg-white/75 p-6 h-40 w-full z-20 flex items-center justify-center flex-col gap-6'>
					<Button
						variant="filled"
						type="button"
						size="full"
						action={() => {
							//trackEntryPage('signup')
							router.push('/signup')
						}}
					>
						Crear nueva cuenta
					</Button>
					<Button
						className='bg-white'
						variant="outlined"
						type="button"
						size="full"
						action={() => {
							//trackEntryPage('login')
							router.push('/login/email')
						}}
					>
						Iniciar sesión
					</Button>
				</div>
			</div>
		</div>
	)
}